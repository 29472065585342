<template>
    <div>
        <FormLabel v-if="label" :label="required ? label + '*' : label" />
        <br v-if="!short">
        <v-textarea
            auto-grow
            outlined
            v-model="newValue"
            :placeholder="placeholder"
            :disabled="disabled"
            :class="errors && errors.length ? 'hasError' : ''"
            :error="errors && errors.length > 0"
            :clearable="clearable || false"
            :rows="rows || 4"
            :counter="counter"
            :maxlength="maxlength == 0 ? null : maxlength"
            @input="emitValue">
            <template v-slot:append><v-icon class="error-icon" v-if="errors.length">mdi-alert</v-icon></template>
        </v-textarea>
        <ErrorMessages :errors="errors"></ErrorMessages>
    </div> 
</template>

<script>
import FormLabel from '@/components/form/FormLabel.vue'
import { trim } from '@/scripts/helper'

export default {
    name: 'FormTextArea',
    components: {
        FormLabel
    },
    props: {
        value: {
            type: String,
            default: null
        },
        rules: String,
        label: String,
        placeholder: String,
        required: Boolean,
        clearable: {
            type: Boolean,
            default: false
        },
        rows: Number,
        counter: {
            type: Boolean,
            default: true
        },
        maxlength: {
            type: Number,
            default: 500
        },
        errors: Array,
        disabled: Boolean,
        short: {
            type: Boolean,
            default: false
        }
    },
    emits: [
        'change'
    ],
    data() {
        return {
            newValue: "",
        }
    },
    methods: {
        emitValue() {
            this.newValue = trim(this.newValue);
            this.$emit('change', this.newValue);  
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(newVal) {
                this.newValue = newVal;
            }
        }
    },
    created() {
        //this.value = this.savedValue;
    }

}

</script>

<style lang="scss">

</style>